import React, { useState ,useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavBar from '../../../components/navigation/Navbar';
import BackPic from '../../../assets/BackPic';
import { useNavigate } from 'react-router-dom';

import 'leaflet/dist/leaflet.css';
import GoogleMapReact from 'google-map-react';





const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};


interface PinMarkerProps {
  lat: number;
  lng: number;
  color?: string;
  size?: string;
  hoverColor?: string;
  tooltip?: string;
}

const PinMarker: React.FC<PinMarkerProps> = ({
  lat,
  lng,
  color = 'blue',
  size = '40px',
  hoverColor = 'darkblue',
  tooltip
}) => (
  <div
    style={{
      position: 'absolute',
      transform: 'translate(-50%, -100%)',
      cursor: 'pointer',
      zIndex: 1000 // Ensure it's above other map elements
    }}
    className={`hover:bg-${hoverColor} transition-all`}
    aria-label={`Location pin for latitude ${lat} and longitude ${lng}`}
  >
    <div
      className={`flex items-center justify-center bg-${color} text-white p-1 rounded-full shadow-lg z-30`}
      style={{ width: size, height: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {/* SVG icon for the pin, you can customize it further if needed */}
      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5 7 13 7 13s7-8 7-13c0-3.87-3.13-7-7-7Zm0 9.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"/>
      </svg>
    </div>
    {tooltip && (
      <div className="tooltip" style={{ position: 'absolute', bottom: '100%', left: '50%', transform: 'translateX(-50%)', marginTop: '8px', padding: '4px 8px', backgroundColor: 'rgba(0,0,0,0.75)', color: 'white', borderRadius: '4px', fontSize: '12px', zIndex: 1000 }}>
        {tooltip}
      </div>
    )}
  </div>
);



type Location = {
  lat: number;
  lng: number;
};


export default function FindSp3() {

  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [markers, setMarkers] = useState<Location[]>([]); // If you are using markers
  const [cursorLocation, setCursorLocation] = useState<Location | null>(null);
  const navigate = useNavigate();
const handleSearch = () => {
  if (selectedLocation) {
    navigate(`/confirm?lat=${selectedLocation.lat}&lon=${selectedLocation.lng}`);
  } else {
    alert('Please select a location first.');
  }
};


  // Function to update cursor location
  const handleMouseMove = (e: GoogleMapReact.ChangeEventValue) => {
    setCursorLocation({
      lat: e.center.lat,
      lng: e.center.lng
    });
  };


    useEffect(() => {
    // Automatically get the current location on load
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserLocation(currentLocation);
        setSelectedLocation(currentLocation); // Set the initial selected location
      },
      (error) => console.error("Error getting location", error),
      { enableHighAccuracy: true }
    );
  }, []);

  const handleMapClick = ({ lat, lng }: { lat: number; lng: number }) => {
    setMarkers([...markers, { lat, lng }]);
        setSelectedLocation({ lat, lng });

  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserLocation(currentLocation);
        setSelectedLocation(currentLocation);
      },
      (error) => {
        console.error("Error getting location", error);
      },
      { enableHighAccuracy: true }
    );
  };
  const handleLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (userLocation) {
      setUserLocation({ ...userLocation, lat: parseFloat(e.target.value) || 0 });
    }
  };

  const handleLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (userLocation) {
      setUserLocation({ ...userLocation, lng: parseFloat(e.target.value) || 0 });
    }
  };


  // @ts-ignore
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <NavBar />
        <section className='bg-gray-50 overflow-hidden relative'>
          <div className='px-4 mx-auto py-6 flex flex-col justify-center items-center'>
            <div className='flex flex-wrap gap-y-4 w-full max-w-xl pb-8 lg:pb-0'>
              <BackPic />
              <div className='w-full font-bold px-2 mb-2 md:mb-4'>
                <p className='text-xl text-center md:text-4xl'>
                  Choose your location :
                </p>
              </div>
              <div className='w-full object-cover mb-8 h-80 rounded-lg relative'>
        <div className="h-80 w-full rounded-lg overflow-hidden relative">
   <GoogleMapReact
  bootstrapURLKeys={{ key: 'AIzaSyC0NIk9KC2v06brlbwcSwjUOb8-P6ylaPE' }}
      center={selectedLocation || { lat: 0, lng: 0 }}
      defaultZoom={19}
      options={{
        gestureHandling: 'greedy',
      }}
      onClick={(e) => {
        setSelectedLocation({
          lat: e.lat,
          lng: e.lng
        });
      }}
      onChildMouseMove={handleMouseMove}
    >
      {cursorLocation && (
        <PinMarker
          lat={cursorLocation.lat}
          lng={cursorLocation.lng}
          color="blue-600"
          hoverColor="blue-700"
          tooltip="Current Cursor Position"
        />
      )}
    </GoogleMapReact>

          <button
            className="absolute top-2 left-2 bg-white p-2 rounded-full shadow-md text-black z-10"
            onClick={getCurrentLocation}
          >
            Locate Me
          </button>
        </div>

                <a className='...' href='#' onClick={getCurrentLocation}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    className='w-6 h-6'
                  >
                    <path d='M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z' />
                  </svg>
                  <p className='flex-1'>Use current location</p>
                </a>
              </div>

              <div className='w-full flex flex-wrap items-center mb-2'>
                <div className='w-full p-2'>
                  <p className='block mb-1.5 text-sm text-gray-900 font-semibold'>
                    Location
                  </p>
                  <div className='w-full flex items-center overflow-hidden border rounded-lg bg-white focus-within:border-blue-500'>
                    <div className='pl-3.5'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={2}
                        stroke='currentColor'
                        aria-hidden='true'
                        className='text-gray-400 w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                        />
                      </svg>
                    </div>
            <input
              type='text'
              value={selectedLocation ? selectedLocation.lat : ''}
              onChange={handleLatitudeChange}
              placeholder='Latitude'
            />

                                <input
              type='text'
              value={selectedLocation ? selectedLocation.lng : ''}
              onChange={handleLongitudeChange}
              placeholder='Longitude'
            />

                  </div>
                </div>
                <div className='w-full p-2 md:w-1/3'>
                  <p className='block mb-1.5 text-sm text-gray-900 font-semibold'>
                    Entrance
                  </p>
                  <div className='w-full flex items-center overflow-hidden border rounded-lg bg-white focus-within:border-blue-500'>
                    <div className='pl-3.5 hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={2}
                        stroke='currentColor'
                        aria-hidden='true'
                        className='text-gray-400 w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                      </svg>
                    </div>
                    <input
                      className='w-full bg-transparent outline-none px-3 py-2'
                      id='inputsInput7-1'
                      type='text'
                      placeholder='Entrance'
                    />
                  </div>
                </div>
                <div className='w-full p-2 md:w-1/3'>
                  <p className='block mb-1.5 text-sm text-gray-900 font-semibold'>
                    Floor
                  </p>
                  <div className='w-full flex items-center overflow-hidden border rounded-lg bg-white focus-within:border-blue-500'>
                    <div className='pl-3.5 hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={2}
                        stroke='currentColor'
                        aria-hidden='true'
                        className='text-gray-400 w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                      </svg>
                    </div>
                    <input
                      className='w-full bg-transparent outline-none px-3 py-2'
                      id='inputsInput7-1'
                      type='text'
                      placeholder='Floor'
                    />
                  </div>
                </div>
                <div className='w-full p-2 md:w-1/3'>
                  <p className='block mb-1.5 text-sm text-gray-900 font-semibold'>
                    Apartment
                  </p>
                  <div className='w-full flex items-center overflow-hidden border rounded-lg bg-white focus-within:border-blue-500'>
                    <div className='pl-3.5 hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={2}
                        stroke='currentColor'
                        aria-hidden='true'
                        className='text-gray-400 w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                      </svg>
                    </div>
                    <input
                      className='w-full bg-transparent outline-none px-3 py-2'
                      id='inputsInput7-1'
                      type='text'
                      placeholder='Apartment'
                    />
                  </div>
                </div>
                <div className='w-full p-2'>
                  <p className='block mb-1.5 text-sm text-gray-900 font-semibold'>
                    Additional information
                  </p>
                  <div className='w-full flex items-center overflow-hidden border rounded-lg bg-white focus-within:border-blue-500'>
                    <div className='pl-3.5 hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={2}
                        stroke='currentColor'
                        aria-hidden='true'
                        className='text-gray-400 w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                        />
                      </svg>
                    </div>
                    <input
                      className='w-full bg-transparent outline-none px-3 py-2'
                      id='inputsInput7-1'
                      type='text'
                      placeholder='Additional information'
                    />
                  </div>
                </div>
              </div>
             <a
    href='#'
    onClick={handleSearch}
    className='relative group lg:block w-full py-3 px-5 text-center text-sm font-semibold overflow-hidden bg-blue-500 hover:bg-blue-600 rounded-xl text-white'
  >
    Search for plumbers
  </a>


            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

