import React from 'react';


const LSPic = () => {
    return (
        <div className='flex items-center justify-center mb-2 mx-auto relative'>
            <svg
                id='generatedsvg'
                width={200}
                height={200}
                viewBox='0 0 200 200'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                className='w-18 h-18 text-green-500'
            >
                <path
                    d='M141.85929140373304,99.99999999999999C137.5550776403709,112.08399891711804,141.87970232007746,120.46637636679316,136.98395161945717,126.87041370522837C132.0882009188369,133.2744510436636,122.29083682431923,131.69352532784168,112.48478720001137,138.4242240306113C102.67873757570351,145.15492273338091,88.2770723612334,167.8109780873766,78.14765387360998,167.254605921846C68.01823538598657,166.6982337563154,54.85463858332236,146.29509202440204,51.7082762742709,135.0859910374277C48.56191396521944,123.87689005045337,61.85366816456366,113.57285293370968,59.26948001930123,100C56.685291874038796,86.42714706629032,32.25054591020345,62.37662536024073,36.2031474026963,53.64887343516961C40.155748895189156,44.921121510098494,68.88354780437284,50.583961302404305,82.98508897425836,47.633488449573285C97.08663014414388,44.683015596742266,107.50837021778082,34.8238810260257,120.81239442200942,35.9460363181835C134.11641862623802,37.0681916103413,159.30141803600935,43.69075958888398,162.80923419962994,54.36642020252006C166.31705036325053,65.04208081615614,146.16350516709517,87.91600108288193,141.85929140373304,99.99999999999999C137.5550776403709,112.08399891711804,141.87970232007746,120.46637636679316,136.98395161945717,126.87041370522837'
                    className='generate-path'
                    fill='currentColor'
                />
            </svg>
            <div className='absolute z-10'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height={24}
                    viewBox='0 -960 960 960'
                    width={24}
                    className='w-10 h-10'
                >
                    <path d='M110.771-190.771v-59.999h212.307q-20.077-78.461-76.961-135.345Q189.233-443 110.771-463.076q18.462-4.616 36.423-6.923 17.962-2.308 37.423-2.308 123.615 0 209.499 78.96Q480-314.386 480-190.771H110.771Zm429.228 0q0-31.23-6.116-63.114-6.115-31.884-18.499-61.807 37.384-67.923 104.692-112.269 67.307-44.346 155.307-44.346 19.461 0 37.423 2.308 17.961 2.307 36.423 6.923Q770.767-443 714.383-386.115q-56.384 56.884-77.461 135.345h212.307v59.999h-309.23Zm-55.383-182.843q-4.231-65 15.538-121.615 19.768-56.615 57.922-99.538 38.154-42.923 91.576-69.116 53.423-26.192 115.5-28.961-59.846 37.692-98 90.039-38.153 52.346-56.538 111.884-38.23 19.077-72.038 50.923-33.807 31.846-53.96 66.384Zm-56.461-64.999q-12.769-13.231-29-25.847-16.231-12.615-33.462-21.769 1.154-7.154 2.346-16.347 1.192-9.192 1.192-17.885 0-70.23-22.269-132.845-22.269-62.615-62.807-114.384 63.308 25.846 111.231 77.692 47.923 51.847 70.461 124.001-15.308 28.461-24.846 61.769t-12.846 65.615Z' />
                </svg>
            </div>
        </div>

    )
}
export default LSPic;