import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavBar from '../../../components/navigation/Navbar';
import BackPic from '../../../assets/BackPic';
import { useLocation } from 'react-router-dom';
import { fetchNearbyServiceProviders } from '../../../services/suggest-serviceprovider'; // Adjust the import path as necessary
import { ServiceProvider } from '../../../types/ServiceProviders'; // Adjust the import path as necessary


const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

const FindSp4 = () => {
  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const lat = query.get('lat');
  const lon = query.get('lon');
  const [serviceProviders, setServiceProviders] = useState<ServiceProvider[]>([]);

    useEffect(() => {
    // @ts-ignore
      if (!isNaN(lat) && !isNaN(lon)) {
fetchNearbyServiceProviders(lat, lon)
  .then((data: ServiceProvider[]) => setServiceProviders(data))
  .catch((error: any) => console.error('Failed to fetch service providers:', error));
    }
  }, [lat, lon]);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <NavBar />
        <section className='relative overflow-hidden bg-gray-50'>
          <div className='relative px-4 mx-auto py-6 flex justify-center items-center'>
            <div className='flex flex-wrap gap-y-4 max-w-md max-w-lg max-w-xl'>
              <BackPic />
              <div className='w-full font-bold px-2 mb-2 md:mb-4'>
                <p className='text-xl text-center md:text-4xl'>
                  Plumbers available near you:
                </p>
              </div>
              <div className='flex justify-center w-full flex-wrap gap-y-8'>
     {serviceProviders.map((provider) => (
  <div key={provider.id} className='flex gap-x-4 bg-white rounded-xl inline-block shadow-lg p-4 w-full text-sm md:text-base my-2'>
    <img
      src={provider.picture.startsWith('http') ? provider.picture : `https://server.fixi.website${provider.picture}`}
      alt={`${provider.first_name} ${provider.last_name}`}
      className='w-16 h-16 rounded-full object-cover'
    />
    <div className='flex-1 flex flex-col justify-between'>
      <p className='font-semibold text-lg'>{`${provider.first_name} ${provider.last_name}`}</p>
      <div className='flex items-center mt-1'>
        {[...Array(5)].map((_, index) => (
          <svg
            key={index}
            xmlns='http://www.w3.org/2000/svg'
            fill={index < Math.round(provider.average_rating) ? 'currentColor' : 'none'}
            viewBox='0 0 24 24'
            strokeWidth={2}
            stroke='currentColor'
            className='w-5 h-5 text-blue-500'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.915a1 1 0 00.95-.69l1.519-4.674z'
            />
          </svg>
        ))}
        <span className='ml-2 text-gray-600'>{provider.average_rating.toFixed(1)} / {provider.reviews.length} reviews</span>
      </div>
      <p className='text-gray-600 mt-1'>Distance: {provider.distance} m</p>
    <button
  onClick={() => {/* call function to handle click event */}}
  className='mt-2 inline-flex items-center justify-center px-3 py-1 text-xs font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 active:bg-blue-700 transition duration-150 ease-in-out transform hover:scale-105'
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-4 h-4 mr-1 -ml-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 10h12M9 16h6M9 20h4M9 8h.01M13 4L9 8m4 0L9 4m0 4h4"
    />
  </svg>
  Call {provider.first_name}
</button>

    </div>
  </div>
))}
            </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

export default FindSp4;
