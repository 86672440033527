import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './components/navigation/Navbar';
import IndexPage from './pages/Index';
import LoginPage from './pages/components/Authentication/Sign-in';
import SignUpPage from './pages/components/Authentication/Sign-up';
import SignUpCustomerPage from './pages/components/Authentication/Sign-up-customer';
import SignUpSpPage from './pages/components/Authentication/Sign-up-sp';
import FindSp3 from './pages/components/FindServiceProvider/AddressConfirmation';
import FindSp2 from './pages/components/FindServiceProvider/Categories';
import CustomizedServiceForm from './pages/components/FindServiceProvider/Customised';
import FindSp4 from './pages/components/FindServiceProvider/ProviderSelection';
import ProfilePage from './pages/components/profile/customer';
import ServiceProviderDetail from './pages/components/FindServiceProvider/ServiceProviderDetail';
import { ServiceProviderProvider } from './Context/SPContext';

const App: React.FC = () => {
  return (
    <ServiceProviderProvider>
      <Router>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signup/customer" element={<SignUpCustomerPage />} />
          <Route path="/signup/sp" element={<SignUpSpPage />} />
          <Route path="/navbar" element={<Navbar />} />
          <Route path="/services" element={<FindSp2 />} />
          <Route path="/personalinformation" element={<FindSp3 />} />
          <Route path="/confirm" element={<FindSp4 />} />
          <Route path="/customised" element={<CustomizedServiceForm />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/SPprofile/:providerId" element={<ServiceProviderDetail />} />
        </Routes>
      </Router>
    </ServiceProviderProvider>
  );
};

export default App;
