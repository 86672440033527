// src/services/apiService.ts
import { ServiceProvider } from '../types/user';

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
const PIC_URL = process.env.PIC_URL;
export const fetchServiceProviders = async (): Promise<ServiceProvider[]> => {
  const response = await fetch(`${API_BASE_URL}users/service-providers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // Include other necessary headers such as Authorization if required
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const serviceProviders = await response.json();

  // Append API_BASE_URL to the picture property
  const updatedProviders = serviceProviders.map((provider: ServiceProvider) => ({
    ...provider,
    picture: `https://server.fixi.website/${provider.picture}`
  }));

  return updatedProviders;
};
