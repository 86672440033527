// ServiceProviderContext.tsx

import React, { createContext, useState, useContext, ReactNode } from 'react';
import { ServiceProvider, ServiceProviderContextType } from './Service';

const ServiceProviderContext = createContext<ServiceProviderContextType | undefined>(undefined);

export const useServiceProviders = () => {
    const context = useContext(ServiceProviderContext);
    if (!context) {
        throw new Error('useServiceProviders must be used within a ServiceProviderProvider');
    }
    return context;
};

export const ServiceProviderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [serviceProviders, setServiceProviders] = useState<ServiceProvider[]>([]);

    const updateServiceProviders = (data: ServiceProvider[]) => {
        setServiceProviders(data);
    };

    return (
        <ServiceProviderContext.Provider value={{ serviceProviders, updateServiceProviders }}>
            {children}
        </ServiceProviderContext.Provider>
    );
};
