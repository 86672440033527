// Modal.tsx
import React from 'react';

type ModalProps = {
    showModal: boolean;
    closeModal: () => void;
    message: string;
};

const Modal: React.FC<ModalProps> = ({ showModal, closeModal, message }) => {
    if (!showModal) return null;

    return (
        <div className='fixed inset-0 z-50 overflow-auto bg-smoke-light flex'>
            <div className='relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg'>
                <div>
                    <h4 className='text-lg font-bold'>{message}</h4>
                </div>
                <div className='mt-4'>
                    <button
                        onClick={closeModal}
                        className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
