import React from "react";

const ElectPic = () => {
    return (
        <div className='flex items-center justify-center mb-2 mx-auto relative'>
            <svg
                id='generatedsvg'
                width={200}
                height={200}
                viewBox='0 0 200 200'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                className='text-yellow-500 w-18 h-18'
            >
                <path
                    d='M150.39724550909952,99.99999999999999C150.9505341960016,108.25838431117006,145.37146207267944,118.74982388700201,140.2107565203598,125.84188407805227C135.05005096804018,132.93394426910254,127.29724469800404,138.77439578071593,119.4330121951817,142.55236114630154C111.56877969235937,146.33032651188714,101.10492333002347,150.01524032285704,93.02536150342583,148.50967627156587C84.94579967682819,147.0041122202747,79.09912778186323,138.87145638206718,70.95564123559586,133.51897683855447C62.812154689328494,128.16649729504175,47.54820358226147,124.3962226642197,44.16444222582164,116.39479901048955C40.780680869381804,108.39337535675939,45.94499617615006,93.54910245868861,50.65307309695687,85.51043491617352C55.36115001776367,77.47176737365844,65.38926943232264,74.09998578233734,72.41290375066247,68.16279375539906C79.4365380690023,62.22560172846079,84.81632736659036,51.98378708366407,92.79487900699583,49.88728275454386C100.7734306474013,47.79077842542365,112.93485602777005,51.183051871263146,120.2842135930953,55.583767780677796C127.63357115842055,59.984483690092446,131.87218574627994,68.88887284114475,136.8910243989473,76.29157821103178C141.90986305161468,83.6942835809188,149.84395682219744,91.74161568882991,150.39724550909952,99.99999999999999C150.9505341960016,108.25838431117006,145.37146207267944,118.74982388700201,140.2107565203598,125.84188407805227'
                    className='generate-path'
                    fill='currentColor'
                />
            </svg>
            <div className='absolute z-10'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height={24}
                    viewBox='0 -960 960 960'
                    width={24}
                    className='w-10 h-10'
                >
                    <path d='m422-232 207-248H469l29-227-185 267h139l-30 208Zm-78.921 129.691 40-277.692H198.08l329.613-475.766h49.228l-39.615 315.768h219.999l-364.998 437.69h-49.228ZM471-470Z' />
                </svg>
            </div>
        </div>
    );

}

export default ElectPic;