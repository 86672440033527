// ServicesSection.tsx
import React, { useState } from 'react';
import Modal from '../../../components/Model'; // Correct the path as necessary
import CleanPic from '../../../assets/CleanPic';
import ElectPic from '../../../assets/ElectPic';
import HPic from '../../../assets/HVACPic';
import LSPic from '../../../assets/LScaPic';
import PaintPic from '../../../assets/PaintPic';
import PlumbPic from '../../../assets/plumPic';
import { useTranslation } from 'react-i18next';
// ... import other icon components

type ServiceCardProps = {
    type: string;
    Icon: React.ElementType;
    offered: boolean;
    label?: string;
    onClick: () => void;
};

const ServiceCard: React.FC<ServiceCardProps> = ({ type, Icon, offered, onClick }) => {
    return (
        <div className='px-2 w-1/3 flex justify-center md:w-1/6'>
            <div
                className={`bg-white rounded-xl inline-block shadow p-3 text-center cursor-pointer ${offered ? 'hover:text-blue-500' : 'cursor-not-allowed'}`}
                onClick={onClick}
            >
                <Icon />
                <h5 className='font-semibold text-xs text-center'>{type} services</h5>
            </div>
        </div>
    );
};

const ServicesSection = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const { t } = useTranslation();

    const handleServiceClick = (offered: boolean, type: string) => {
        if (!offered) {
            setModalMessage(`${type} services are not offered yet, it will be available soon`);
            setShowModal(true);
        } else {
            // Redirect to service page
            window.location.href = `/services?type=${type}`;
        }
    };

    const closeModal = () => setShowModal(false);


    const services = [
        {
            type: t('Services.Plumbing'),
            Icon: PlumbPic,
            offered: true,
            label: 'Plumbing',
        },
        {
            type: t('Services.Electrical'),
            Icon: ElectPic,
            offered: false,
            label: 'Electrical',
        },
        {
            type: t('Services.Cleaning'),
            Icon: CleanPic,
            offered: true,
            label: 'Cleaning',
        },
        {
            type: t('Services.Painting'),
            Icon: PaintPic,
            offered: false,
            label: 'Painting',
        },
        {
            type: t('Services.HVAC'),
            Icon: HPic,
            offered: false,
            label: 'HVAC',
        },
        {
            type: t('Services.Landscaping'),
            Icon: LSPic,
            offered: false,
            label: 'Landscaping',
        },
    ];

    return (
        <>
            <Modal showModal={showModal} closeModal={closeModal} message={modalMessage} />
            <section className='relative overflow-hidden bg-gray-50 z-20 py-6'>
                <div className='relative container px-4 mx-auto'>
                    <div className='flex flex-wrap gap-y-4'>
                        <div className='w-full font-bold px-2'>
                            <p className='text-xl'>{t("lookingFor")}</p>
                        </div>
                        {services.map((service, index) => (
                            <ServiceCard
                                key={index}
                                type={service.type}
                                Icon={service.Icon}
                                offered={service.offered}
                                onClick={() => handleServiceClick(service.offered, service.label)}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesSection;
