import React, { useState } from 'react';
import NavBar from '../../../components/navigation/Navbar';
import { useNavigate } from 'react-router-dom';

const CustomizedServiceForm: React.FC = () => {
    const [description, setDescription] = useState('');
    const [images, setImages] = useState<File[]>([]);
    const navigate = useNavigate();


    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setImages(Array.from(event.target.files));
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Handle the submission to the backend here
        //navigate('/findsp4');
        navigate('/personalinformation', { state: { description, images } });

        // console.log(description, images);
    };
    return (
        <>
            <NavBar />
            <div className="container mx-auto p-4 min-h-screen bg-blue-50">
                <div className="flex flex-col items-center">
                    <div className="bg-white shadow rounded-lg p-6 mt-6 w-full max-w-lg">
                        <h2 className="text-2xl font-bold text-blue-700 mb-4">Describe Your Problem</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="problem-description" className="block text-sm font-medium text-gray-700">
                                    Details
                                </label>
                                <textarea
                                    id="problem-description"
                                    name="problem-description"
                                    rows={4}
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Please provide as many details as possible."
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">
                                    Upload Images (Optional)
                                </label>
                                <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    multiple
                                    className="block w-full text-sm text-gray-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-blue-500 file:text-white
                                    hover:file:bg-blue-600"
                                    onChange={handleImageChange}
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomizedServiceForm;
