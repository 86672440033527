import axios from 'axios';
import {LoginResponse} from '../types/user';

import { RegisterPayload, RegisterResponse, ServiceResponse } from '../types/user';

const apiBaseUrl = process.env.REACT_APP_BASE_API_URL;

export const login = async (username: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await axios.post<LoginResponse>(`${apiBaseUrl}users/login/`, {
      username,
      password,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data || { message: 'An unknown error occurred' };
    }
    throw new Error('An unknown error occurred');
  }
};

export const registerUser = async (formPayload: RegisterPayload): Promise<ServiceResponse<RegisterResponse>> => {
  try {
    const response = await axios.post<RegisterResponse>(`${apiBaseUrl}users/register/`, formPayload);
    return { success: true, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Check if the error status is 500
      if (error.response && error.response.status === 500) {
        return { success: true, data: error.response.data };
      }
      return { success: false, error: error.response?.data.message || 'An error occurred during registration.' };
    }
    return { success: false, error: 'An unknown error occurred' };
  }
};

