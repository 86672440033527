// src/services/apiService.ts
import { ServiceProviders } from '../types/ServiceProviders';

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

// ...other service functions...

// Fetch nearby service providers
export const fetchNearbyServiceProviders = async (latitude: string | null, longitude: string | null): Promise<ServiceProviders> => {
    const endpoint = `users/service-provider/nearby/?latitude=${latitude}&longitude=${longitude}`;
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other necessary headers such as Authorization if required
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};
