import React from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../../../components/navigation/Navbar';
import { useServiceProviders } from '../../../Context/SPContext';


const ServiceProviderDetail: React.FC = () => {
    const { providerId } = useParams<{ providerId?: string }>();
    const { serviceProviders } = useServiceProviders();


    if (!providerId) {
        // Handle the undefined case
        return <div>Provider ID not found.</div>;
    }

    const numericProviderId = parseInt(providerId, 10);

    // const { serviceProviders } = useServiceProviders();

    console.log(serviceProviders.find((provider) => provider.id === 1));

    const provider = serviceProviders.find((provider) => provider.id === numericProviderId);
    console.log(provider);
    const providerDetails = {
        id: provider?.id,
        firstName: provider?.first_name,
        lastName: provider?.last_name,
        picture: provider?.picture,
        taxonomy: provider?.taxonomy,
        averageRating: 4.5,
        portfolio: "Link to portfolio",
        bio: "John has been a professional plumber for over 10 years, providing top-notch services to a wide range of clients...",
        thankYouNote: "Thank you for considering my services. Looking forward to working with you."
    };

    // if (!provider) {
    //     return <div>Provider not found</div>;
    // }
    return (
        <div >
            <NavBar />
            <div className="max-w-2xl mx-auto bg-white rounded-lg border border-gray-200 shadow-md overflow-hidden">
                <img
                    className="w-full h-64 object-cover object-center"
                    src={providerDetails.picture}
                    alt={`${providerDetails.firstName} ${providerDetails.lastName}`}
                />
                <div className="p-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">
                        {provider?.first_name} {provider?.last_name}
                    </h2>
                    <p className="text-blue-500 font-semibold">{provider?.taxonomy}</p>
                    <p className="mb-4">Rating: <span className="text-blue-500 font-semibold">{provider?.average_rating} / 5</span></p>
                    <p className="mb-4"><a href={providerDetails.portfolio} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">View Portfolio</a></p>

                    <h4 className="text-xl font-semibold text-gray-800 mb-2">Bio</h4>
                    <p className="text-gray-700 mb-4">{providerDetails.bio}</p>
                    <h4 className="text-xl font-semibold text-gray-800 mb-2">Thank You Note</h4>
                    <p className="text-gray-700">{providerDetails.thankYouNote}</p>
                </div>
            </div>
        </div>
    );
}

export default ServiceProviderDetail;
