import React from 'react';

interface NavigationLink {
    title: string;
    href: string;
}

interface NavigationMenuProps {
    links: NavigationLink[];
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ links }) => {
    return (
        <ul className="flex flex-col space-y-4 mt-4">
            {links.map((link, index) => (
                <li key={index} className="text-lg font-medium">
                    <a
                        href={link.href}
                        className="text-gray-700 hover:text-blue-600 transition-colors duration-200"
                    >
                        {link.title}
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default NavigationMenu;
