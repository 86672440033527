import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationES from './locales/fr.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationES
    }
};

// Function to detect user's language
const detectUserLanguage = () => {
    // Check if user's language preference is stored in localStorage
    const savedLanguage = localStorage.getItem('i18nextLng');

    // If found, use the saved language, otherwise default to 'en'
    return savedLanguage || 'en';
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: detectUserLanguage(), // default language
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
