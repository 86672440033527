// src/services/apiService.ts
import { Review } from '../types/Reviews';

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchReviews = async (): Promise<Review[]> => {
  const endpoint = 'reviews/review/';
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // Include other necessary headers such as Authorization if required
    },
  });
    console.log(API_BASE_URL);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};
