import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "Fixi | Sign Up ",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function SignUpSp() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="overflow-hidden py-12">
        <div className="container px-4 mx-auto">
          <h3 className="font-heading text-gray-900 font-semibold mb-4 text-xl text-center">
            Sign up as Service Provider
          </h3>
          <form action="" className="flex flex-col justify-center gap-y-6">
            <div className="">
              <label
                className="block mb-1.5 text-sm text-gray-900 font-semibold"
                htmlFor=""
              >
                Full name
              </label>
              <input
                className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 rounded-lg outline-none focus:border-blue-500"
                type="text"
                placeholder="John Doe"
              />
            </div>
            <div className="">
              <label
                className="block mb-1.5 text-sm text-gray-900 font-semibold"
                htmlFor=""
              >
                Email
              </label>
              <input
                className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 rounded-lg outline-none focus:border-blue-500"
                type="email"
                placeholder="name@email.com"
              />
            </div>
            <div className="">
              <label
                className="block mb-1.5 text-sm text-gray-900 font-semibold"
                htmlFor=""
              >
                Phone
              </label>
              <input
                className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 rounded-lg outline-none focus:border-blue-500"
                type="tel"
                placeholder="06XXXXXXXX"
              />
            </div>
            <div className="">
              <div className="flex mb-1.5 items-center justify-between">
                <label
                  className="block text-sm text-gray-900 font-semibold"
                  htmlFor=""
                >
                  Password
                </label>
              </div>
              <div className="relative">
                <input
                  className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 rounded-lg focus:border-blue-500 outline-none"
                  type="password"
                  placeholder="********"
                />
                <button className="absolute top-1/2 right-0 mr-3 transform -translate-y-1/2 inline-block hover:scale-110 transition duration-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-4 h-4 text-gray-400 hover:text-blue-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="">
              <label
                className="block mb-1.5 text-sm text-gray-900 font-semibold"
                htmlFor=""
              >
                City
              </label>
              <select className="w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 rounded-lg focus:border-blue-500 outline-none bg-white">
          <option selected={true}>Choose a city</option>
                <option value={1}>Agadir</option>
                <option value={2}>Casablanca</option>
                <option value={3}>Fes</option>
                <option value={4}>Tanger</option>
              </select>
            </div>
            <button
              className="relative group block w-full py-3 px-5 text-center text-sm font-semibold overflow-hidden bg-blue-500 hover:bg-blue-600 rounded-xl text-white"
              type="submit"
            >
              <span className="relative">Sign up</span>
            </button>
            <span className="text-xs font-semibold text-gray-900 text-center">
              <span>Already have an account?</span>
              <a
                className="ml-1 inline-block text-blue-500 hover:text-blue-600"
                href="sign-in.html"
              >
                Log in
              </a>
            </span>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}
