import React from "react";


const PlumbPic = () => {
    return (
        <div className='flex items-center justify-center mx-auto relative mb-1 md:mb-2'>
            <svg
                id='generatedsvg'
                width={200}
                height={200}
                viewBox='0 0 200 200'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                className='text-blue-300 w-18 h-18'
            >
                <path
                    d='M164.25460951891938,99.99999999999999C164.8522561084175,111.39932942308151,161.82019360989597,126.82147630779677,155.00604821009676,135.3502406928691C148.19190281029756,143.87900507794143,133.8048426319556,148.24911818034235,123.36973712012416,151.17258631043399C112.93463160829272,154.09605444052562,102.26299426508002,154.52703954761012,92.39541513910811,152.89104947341886C82.5278360131362,151.2550593992276,72.92122651102882,147.22839284250375,64.16426236429268,141.3566458652864C55.407298217556544,135.48489888806904,44.67221379575043,127.72192603321844,39.85363025869127,117.66056761011478C35.035046721632106,107.59920918701113,31.113305162399733,90.7235048861244,35.252761141937725,80.98849532666446C39.39221712147572,71.25348576720452,55.562792415134766,67.65274611217365,64.69036613591922,59.25051025335511C73.81793985670367,50.84827439453658,79.95015481259361,32.94323106478082,90.01820346664444,30.57508017375328C100.08625212069526,28.206929282725742,114.86499719248025,38.97840757708544,125.0986580602242,45.041604907189885C135.33231892796815,51.10480223729433,144.89417676332556,57.79453163891161,151.4201686731081,66.95426415437996C157.94616058289063,76.1139966698483,163.65696292942127,88.60067057691846,164.25460951891938,99.99999999999999C164.8522561084175,111.39932942308151,161.82019360989597,126.82147630779677,155.00604821009676,135.3502406928691'
                    className='generate-path'
                    fill='currentColor'
                />
            </svg>
            <div className='absolute z-10'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height={24}
                    viewBox='0 -960 960 960'
                    width={24}
                    className='w-10 h-10'
                >
                    <path d='M489.846-206.154q10.461-1 17.231-7.828 6.769-6.827 6.769-16.465 0-11.245-7.378-17.822-7.377-6.577-18.853-5.577-41 3-89.308-24.038Q350-304.923 338-371.923q-2-10.231-8.577-16.077-6.577-5.846-15.654-5.846-10.923 0-18.192 8.192-7.269 8.193-4.653 21.039 16.23 87.153 78.461 124.615 62.231 37.461 120.461 33.846Zm-9.917 106.153q-128.467 0-214.197-88.268Q180.001-276.537 180.001-408q0-93.076 74.5-204.037Q329.001-722.999 480-853.844q150.999 130.845 225.499 241.807 74.5 110.961 74.5 204.037 0 131.463-85.801 219.731-85.802 88.268-214.269 88.268ZM480-160q104 0 172-70.5T720-408q0-73-60.5-165T480-774Q361-665 300.5-573T240-408q0 107 68 177.5T480-160Zm0-316.923Z' />
                </svg>
            </div>
        </div>
    );
};

export default PlumbPic;