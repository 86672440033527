import React, { ReactNode } from 'react';

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children }) => {
    return (
        <div
            className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? 'flex' : 'hidden'
                }`}
            aria-labelledby="drawer-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="absolute inset-0 overflow-hidden">
                {/* Background overlay */}
                <div
                    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                    onClick={onClose}
                ></div>

                <section
                    className="absolute inset-y-0 right-0 pl-10 max-w-full flex"
                    aria-labelledby="drawer-heading"
                >
                    {/* Slide-over panel */}
                    <div className="relative w-screen max-w-md">
                        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                            <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <h2 id="drawer-heading" className="text-lg font-medium text-gray-900">
                                        Menu
                                    </h2>
                                    <div className="ml-3 h-7 flex items-center">
                                        <button
                                            className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                                            onClick={onClose}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            {/* Close button */}
                                            <svg
                                                className="h-6 w-6"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={2}
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                {/* Replace with your content */}
                                {children}
                                {/* /End replace */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Drawer;
