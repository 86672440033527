import React from 'react';

const PaintPic = () => {
    return (
        <div className='flex items-center justify-center mb-2 mx-auto relative'>
            <svg
                id='generatedsvg'
                width={200}
                height={200}
                viewBox='0 0 200 200'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                className='w-18 h-18 text-red-500'
            >
                <path
                    d='M153.11054245625533,99.99999999999999C155.31448320316915,109.15430853480035,147.2510927655007,127.67123936478174,139.34086249293395,133.01090320801694C131.43063222036722,138.35056705125214,116.64835681720797,129.8453627278795,105.64916082085493,132.03798305941123C94.6499648245019,134.23060339094297,83.4900751202357,148.1567212744696,73.34568651481571,146.16662519720748C63.20129790939572,144.17652911994537,45.90661281974609,129.81776404086366,44.782829188335,120.09740659583863C43.659045556923914,110.37704915081362,60.620645220038796,96.77215653597653,66.60298472634919,87.84448052705739C72.58532423265959,78.91680451813824,74.0535609622102,70.5007471011036,80.67686622619738,66.53135054232374C87.30017149018455,62.56195398354388,98.76942434606329,62.10248426489641,106.34281631027224,64.02810117437825C113.91620827448119,65.9537180838601,118.32259698712055,72.08973552827783,126.11721801145106,78.08505199921478C133.91183903578158,84.08036847015174,150.90660170934152,90.84569146519962,153.11054245625533,99.99999999999999C155.31448320316915,109.15430853480035,147.2510927655007,127.67123936478174,139.34086249293395,133.01090320801694'
                    className='generate-path'
                    fill='currentColor'
                />
            </svg>
            <div className='absolute z-10'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height={24}
                    viewBox='0 -960 960 960'
                    width={24}
                    className='w-10 h-10'
                >
                    <path d='M574.614-60.002H444.231q-15.077 0-25.615-10.345-10.538-10.347-10.538-25.039v-224.229q0-14.692 10.538-25.039Q429.154-355 444.231-355h35v-138.078q0-5-4.039-8.462-4.039-3.462-9.808-3.462H173.847q-30.692 0-52.269-21.192-21.577-21.192-21.577-51.115v-140.768q0-29.538 21.577-50.73 21.577-21.192 52.269-21.192h64.614v-34.615q0-14.692 10.539-25.038 10.538-10.347 25.615-10.347h469.23q15.077 0 25.615 10.347 10.539 10.346 10.539 25.038v129.228q0 14.692-10.539 25.038-10.538 10.347-25.615 10.347h-469.23q-15.077 0-25.615-10.347-10.539-10.346-10.539-25.038v-34.615h-64.614q-6.154 0-10.001 3.462-3.846 3.462-3.846 8.462v140.768q0 5.385 3.846 8.847Q167.693-565 173.847-565h291.537q30.307 0 52.076 21.192 21.769 21.192 21.769 50.73V-355h35.385q15.076 0 25.422 10.346 10.346 10.347 10.346 25.039v224.23q0 14.691-10.346 25.038-10.346 10.345-25.422 10.345ZM468.076-120h82.308v-175.001h-82.308V-120ZM298.46-720H720v-80H298.46v80Zm169.616 600h82.308-82.308ZM298.46-720v-80 80Z' />
                </svg>
            </div>
        </div>

    )
}

export default PaintPic;