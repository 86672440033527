import React from "react";
import { useNavigate } from 'react-router-dom';


const BackPic = () => {
    const history = useNavigate();
    return (
        <div className='flex w-full'>
            <a
                href='#' onClick={() => history(-1)}
                className='inline-block hover:text-blue-500 flex items-center gap-x-2 rounded-full py-2'
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={2}
                    stroke='currentColor'
                    aria-hidden='true'
                    className='w-6 h-6'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z'
                    />
                </svg>
                <h5 className='font-semibold text-center text-xs md:text-base'>
                    Back
                </h5>
            </a>
        </div>
    );
};
export default BackPic;