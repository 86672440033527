import React from 'react';


const HPic = () => {
    return (
        <div className='flex items-center justify-center mb-2 mx-auto relative'>
            <svg
                id='generatedsvg'
                width={200}
                height={200}
                viewBox='0 0 200 200'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                className='text-orange-300 w-18 h-18'
            >
                <path
                    d='M149.4291655465613,99.99999999999999C150.30432373800704,111.12320505398583,144.23328399405494,129.03311850597376,135.99508973629472,135.99508973629472C127.7568954785345,142.95706096661567,109.4942260332958,144.27596459406155,100,141.7718273819258C90.5057739667042,139.26769016979003,88.16573009402454,127.9322376938011,79.02973353651987,120.97026646348013C69.8937369790152,114.00829523315916,45.92129943229457,107.72736759848266,45.18402065497195,100C44.44674187764934,92.27263240151734,65.47006431507953,80.44657616199771,74.6060608725842,74.60606087258421C83.74205743008886,68.7655455831707,90.64365338082732,65.84860850688639,100,64.95690826351897C109.35634661917268,64.06520802015154,122.5059463298601,63.415344122966175,130.7441405876203,69.25585941237968C138.98233484538054,75.09637470179318,148.55400735511557,88.87679494601414,149.4291655465613,99.99999999999999C150.30432373800704,111.12320505398583,144.23328399405494,129.03311850597376,135.99508973629472,135.99508973629472'
                    className='generate-path'
                    fill='currentColor'
                />
            </svg>
            <div className='absolute z-10'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height={24}
                    viewBox='0 -960 960 960'
                    width={24}
                    className='w-10 h-10'
                >
                    <path d='M480-258.078q92.692 0 157.307-64.615T701.922-480q0-92.692-64.615-157.307T480-701.922q-92.692 0-157.307 64.615T258.078-480q0 92.692 64.615 157.307T480-258.078Zm0-59.614q-31.308 0-59.654-11.846-28.346-11.847-51.5-32.77h222.308q-23.154 20.923-51.5 32.77-28.346 11.846-59.654 11.846Zm-139.539-80q-8.384-15.154-13.769-31.308t-7.385-33.308h321.386q-2 17.154-7.385 33.308t-14.154 31.308H340.461Zm-21.154-100q2-17.154 7.385-33.308t14.154-31.308h278.693q8.384 15.154 13.769 31.308t7.385 33.308H319.307Zm49.539-100q23.154-20.923 51.5-32.77 28.346-11.846 59.654-11.846 31.308 0 59.654 11.846 28.346 11.847 51.5 32.77H368.846ZM212.309-140.001q-29.923 0-51.115-21.193-21.193-21.192-21.193-51.115v-535.382q0-29.923 21.193-51.115 21.192-21.193 51.115-21.193h535.382q29.923 0 51.115 21.193 21.193 21.192 21.193 51.115v535.382q0 29.923-21.193 51.115-21.192 21.193-51.115 21.193H212.309Zm0-59.999h535.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-535.382q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H212.309q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v535.382q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462ZM200-760V-200-760Z' />
                </svg>
            </div>
        </div>

    )
}
export default HPic;