import React from 'react';

const CleanPic = () => {
    return (
        <div className='flex items-center justify-center mb-2 mx-auto relative'>
            <svg
                id='generatedsvg'
                width={200}
                height={200}
                viewBox='0 0 200 200'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                className='w-18 h-18 text-pink-200'
            >
                <path
                    d='M156.1942731482585,99.99999999999999C153.42405086734914,110.51192796541056,136.98916658789327,115.21170954108251,129.27221691088067,124.56230641366132C121.5552672338681,133.91290328624012,118.33463305252559,154.02538680957312,109.89257508618302,156.1035812354728C101.45051711984044,158.18177566137248,87.55806845451903,143.72893181192623,78.61986911282521,137.0314729690594C69.6816697711314,130.33401412619256,60.67476226257795,124.99330773413116,56.263379036020154,115.91882817827185C51.85199580946236,106.84434862241254,47.65994042061024,90.08367839382024,52.15156975347844,82.5845956339035C56.64319908634664,75.08551287398677,73.92187020519467,75.48827019148777,83.21315503322937,70.92433161877142C92.50443986126407,66.36039304605507,97.4525461278354,56.77322969720095,107.89927872168666,55.20096419760535C118.34601131553791,53.62869869800976,137.84438485857493,54.02423265413207,145.8935505963369,61.49073862119784C153.94271633409886,68.95724458826362,158.96449542916787,89.48807203458941,156.1942731482585,99.99999999999999C153.42405086734914,110.51192796541056,136.98916658789327,115.21170954108251,129.27221691088067,124.56230641366132'
                    className='generate-path'
                    fill='currentColor'
                />
            </svg>
            <div className='absolute z-10'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height={24}
                    viewBox='0 -960 960 960'
                    width={24}
                    className='w-10 h-10'
                >
                    <path d='M433.846-509.999h92.308v-286.155q0-19.307-13.423-32.731-13.423-13.423-32.731-13.423t-32.731 13.423q-13.423 13.424-13.423 32.731v286.155ZM197.692-357.692h564.616v-80q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H210.001q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v80Zm-44.922 240h101.077v-90.001q0-12.75 8.629-21.374 8.628-8.625 21.384-8.625 12.755 0 21.371 8.625 8.615 8.624 8.615 21.374v90.001h136.155v-90.001q0-12.75 8.628-21.374 8.629-8.625 21.384-8.625 12.756 0 21.371 8.625 8.615 8.624 8.615 21.374v90.001h136.155v-90.001q0-12.75 8.629-21.374 8.629-8.625 21.384-8.625t21.371 8.625q8.615 8.624 8.615 21.374v90.001H807.23q6.154 0 10.001-4.808 3.846-4.808 1.923-10.578l-44.616-164.616H185.462l-44.616 164.616q-1.923 5.77 1.923 10.578 3.847 4.808 10.001 4.808Zm657.537 59.998H149.693q-34 0-55.307-27.153-21.308-27.154-12.077-60.538l55.385-205.768V-440q0-29.154 20.422-49.576 20.423-20.423 49.577-20.423h166.154v-286.155q0-44.23 30.961-75.191Q435.77-902.306 480-902.306q44.23 0 75.192 30.961 30.961 30.961 30.961 75.191v286.155h166.154q29.154 0 49.577 20.423 20.422 20.422 20.422 49.576v88.847l55.385 206.537q11.077 33-10.538 59.961-21.616 26.961-56.846 26.961ZM762.308-450H197.692h564.616Zm-236.154-59.998h-92.308 92.308Z' />
                </svg>
            </div>
        </div>

    )
}

export default CleanPic;