import React, { useState, useEffect } from 'react';
import { fetchServiceProviders } from '../../services/service-provider'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import { useServiceProviders } from '../../Context/SPContext';

const ServiceProviderList: React.FC = () => {
  const { serviceProviders, updateServiceProviders } = useServiceProviders();


  useEffect(() => {
    const loadServiceProviders = async () => {
      if (serviceProviders.length === 0) {
        try {
          const data = await fetchServiceProviders();
          updateServiceProviders(data);
        } catch (error) {
          console.error("Error fetching service providers:", error);
        }
      }
    };

    loadServiceProviders();
  }, [serviceProviders, updateServiceProviders]);
  console.log(serviceProviders.find((provider) => provider.id === 6));

  return (
    <section className='relative overflow-hidden py-6 bg-gray-50'>
      <div className='relative container px-4 mx-auto'>
        <div className='md:flex px-2'>
          <div className='max-w-sm flex-shrink-0 mb-4'>
            <span className='inline-block py-1 px-3 text-xs font-semibold rounded-full bg-blue-50 text-blue-500 mb-2 uppercase'>
              Trusted Professionals
            </span>
            <h1 className='text-xl w-full font-bold mb-2'>
              Hire trusted and top-rated service providers
            </h1>
            <div className='flex items-center'>
              <button className='inline-flex mr-3 items-center justify-center rounded-full transition duration-200 bg-blue-50 hover:bg-blue-100 text-blue-500 p-2'>
                <svg
                  width={8}
                  height={12}
                  viewBox='0 0 8 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-4 h-4'
                >
                  <path
                    d='M1.17006 5.28995L5.41006 1.04995C5.50302 0.95622 5.61362 0.881826 5.73548 0.831057C5.85734 0.780288 5.98805 0.75415 6.12006 0.75415C6.25207 0.75415 6.38277 0.780288 6.50463 0.831057C6.62649 0.881826 6.73709 0.95622 6.83006 1.04995C7.01631 1.23731 7.12085 1.49076 7.12085 1.75495C7.12085 2.01913 7.01631 2.27259 6.83006 2.45995L3.29006 5.99995L6.83006 9.53995C7.01631 9.72731 7.12085 9.98076 7.12085 10.2449C7.12085 10.5091 7.01631 10.7626 6.83006 10.9499C6.73662 11.0426 6.6258 11.116 6.50396 11.1657C6.38213 11.2155 6.25166 11.2407 6.12006 11.2399C5.98845 11.2407 5.85799 11.2155 5.73615 11.1657C5.61431 11.116 5.5035 11.0426 5.41006 10.9499L1.17006 6.70995C1.07633 6.61699 1.00194 6.50638 0.951166 6.38453C0.900397 6.26267 0.874259 6.13196 0.874259 5.99995C0.874259 5.86794 0.900397 5.73723 0.951166 5.61537C1.00194 5.49351 1.07633 5.38291 1.17006 5.28995Z'
                    fill='currentColor'
                  />
                </svg>
              </button>
              <button className='inline-flex mr-3 items-center justify-center rounded-full transition duration-200 bg-blue-50 hover:bg-blue-100 text-blue-500 p-2'>
                <svg
                  width={8}
                  height={12}
                  viewBox='0 0 8 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-4 h-4'
                >
                  <path
                    d='M6.82994 5.28995L2.58994 1.04995C2.49698 0.95622 2.38638 0.881826 2.26452 0.831057C2.14266 0.780288 2.01195 0.75415 1.87994 0.75415C1.74793 0.75415 1.61723 0.780288 1.49537 0.831057C1.37351 0.881826 1.26291 0.95622 1.16994 1.04995C0.983692 1.23731 0.87915 1.49076 0.87915 1.75495C0.87915 2.01913 0.983692 2.27259 1.16994 2.45995L4.70994 5.99995L1.16994 9.53995C0.983692 9.72731 0.87915 9.98076 0.87915 10.2449C0.87915 10.5091 0.983692 10.7626 1.16994 10.9499C1.26338 11.0426 1.3742 11.116 1.49604 11.1657C1.61787 11.2155 1.74834 11.2407 1.87994 11.2399C2.01155 11.2407 2.14201 11.2155 2.26385 11.1657C2.38569 11.116 2.4965 11.0426 2.58994 10.9499L6.82994 6.70995C6.92367 6.61699 6.99806 6.50638 7.04883 6.38453C7.0996 6.26267 7.12574 6.13196 7.12574 5.99995C7.12574 5.86794 7.0996 5.73723 7.04883 5.61537C6.99806 5.49351 6.92367 5.38291 6.82994 5.28995Z'
                    fill='currentColor'
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className='flex -mx-4 flex-wrap gap-y-8'>
            {serviceProviders.slice(0, 4).map(provider => (

              <div key={provider.id} className='flex-shrink-0 px-4 border-r border-gray-100 w-2/4'>
                <Link
                  to={{
                    pathname: `/SPprofile/${provider.id}`,
                  }}
                  className='block group text-sm space-y-1'
                >
                  <img
                    className='block w-full object-cover h-32 rounded'
                    src={provider.picture}
                    alt={`${provider.first_name} ${provider.last_name}`}
                  />
                  <span className='block text-gray-500 text-xs'>{provider.taxonomy}</span>
                  <h4 className='font-semibold group-hover:text-blue-500'>
                    {provider.first_name} {provider.last_name}
                  </h4>
                  <div className='flex gap-x-1 items-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={2}
                      stroke='currentColor'
                      aria-hidden='true'
                      className='w-6 h-6 text-blue-500'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5'
                      />
                    </svg>
                    <span className='font-bold'>
                      {((provider.average_rating / 5) * 100).toFixed(2)}%
                    </span>
                  </div>
                </Link>
              </div>

            ))}
          </div>            </div>
      </div>
    </section>


  );
}
export default ServiceProviderList;