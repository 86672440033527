import React, { useState } from 'react';
import {jwtDecode} from 'jwt-decode';

import logo from '../../assets/logo.png';
interface DecodedToken {
  picture?: string;
  // ... other properties you expect in the token
}
const Footer = () => {
  const authToken = localStorage.getItem('authToken');
  let userImage;

    if (authToken) {
    try {
      const decoded = jwtDecode<DecodedToken>(authToken);
      userImage = decoded.picture ? `https://server.fixi.website${decoded.picture}` : null;
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }

  return (
      <>
      <section className='pb-5 lg:hidden bottom-0 z-50 w-full bg-white shadow-2xl'>
    <div className=' w-full  lg:px-12 bg-white  p-6 border-gray-200 border-t py-0 '>
      <div className='flex justify-between items-center'>
        <div className='flex w-full items-center flex-wrap grid grid-cols-2'>
          <div className='flex justify-center'>
            <a
              className='inline-block text-blue-500 flex flex-wrap justify-center p-2 text-xs'
              href='/'
            >
              <div className='w-full justify-center flex'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  fill='none'
                  viewBox='0 0 32 32'
                  strokeWidth='2.5'
                  stroke='currentColor'
                  aria-hidden='true'
                  className=''
                >
                  <path
                    className='st0'
                    d='M26,28c0.7,0,1.3-0.6,1.3-1.3V14.7c0-0.9-0.4-1.7-1.1-2.3L16,4L5.8,12.4c-0.7,0.6-1.1,1.4-1.1,2.3v11.9
        C4.7,27.4,5.3,28,6,28h5c0.7,0,1.3-0.6,1.3-1.3v-5.3c0-0.7,0.6-1.3,1.3-1.3h4.7c0.7,0,1.3,0.6,1.3,1.3v5.3c0,0.7,0.6,1.3,1.3,1.3H26
        z'
                  />
                </svg>
              </div>
              <span className='block font-bold'>Home</span>
            </a>
          </div>
              <div className='flex justify-center items-center'>
      {userImage ? (
        <a className='inline-block' href='/profile'>
          <img src={userImage} alt='Profile' className='h-8 w-8 rounded-full' />
        </a>
      ) : (
        <a className='inline-block hover:text-blue-500 p-2 text-xs text-gray-400' href='/signin'>
          <div className='w-full justify-center flex'>
            {/* SVG for default avatar */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={24}
              height={24}
              fill='none'
              viewBox='0 0 32 32'
              strokeWidth='2.5'
              stroke='currentColor'
              aria-hidden='true'
              className=''
            >
              <path
                className='st0'
                d='M6.8,23.7c2.8-1.6,6-2.4,9.2-2.4c3.3,0,6.5,0.9,9.2,2.4 M20,13.3c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4
                  S20,11.1,20,13.3z M28,16c0,6.6-5.4,12-12,12S4,22.6,4,16S9.4,4,16,4S28,9.4,28,16z'
              />
            </svg>
          </div>
          <span className='block'>Log in</span>
        </a>
      )}
    </div>

        </div>
      </div>
    </div>

    </section>
              {/* Desktop Footer (Visible only on large screens) */}
<footer className='hidden lg:flex flex-col justify-between items-center w-full bg-blue-500 text-white py-8'>
        <div className='container mx-auto flex flex-wrap justify-between items-center px-6'>
          {/* Logo and company name */}
          <div className='mb-4'>
            <a href='/' className='flex items-center'>
              <img src={logo} alt='Fixi' className='h-12' />
              <span className='text-2xl font-bold ml-2'>Fixi</span>
            </a>
          </div>

          {/* Important Links */}
          <div className='mb-4'>
            <h2 className='text-xl font-bold mb-3'>Important Links</h2>
            {/* List your important links here */}
            {/* You can add <ul><li>...</li></ul> for each link */}
          </div>

          {/* Social Media and Contact Info */}
          <div className='mb-4'>
            <h2 className='text-xl font-bold mb-3'>Follow Us</h2>
            <div className='flex space-x-4 mb-3'>
              {/* Social media icons */}
              {/* You can add <a>...</a> for each social media icon */}
            </div>
            <div className='text-sm'>
              <p>0000, 00000, Casablanca</p>
              <p>N/A</p>
              <p>info@fixi.com</p>
            </div>
          </div>
        </div>
        <div className='text-center text-sm py-4 mt-8 w-full border-t border-blue-700'>
          © {new Date().getFullYear()} Fixi. All rights reserved.
        </div>
      </footer>      </>

    );
}

export default Footer;