import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom'; // If you're using react-router
import Footer  from '../../../components/navigation/footer';
import NavBar from "../../../components/navigation/Navbar";
// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Define the structure of your user data based on the JWT payload structure
interface UserData {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  id: number;
  phone_number: string;
  picture: string;
}

const ProfilePage = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
    const navigate = useNavigate(); // This is for navigation after logout

  const getProfileImageUrl = (picture: string | undefined) => {
    if (picture) {
      if (picture.startsWith('http')) {
        return picture;
      }
      return `https://server.fixi.website${picture}`;
    }
    // Return a default avatar image if `picture` is undefined or null
    return 'https://server.fixi.website/default-avatar.png'; // Replace with the path to your default avatar image
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log(token)
    if (token) {
      try {
        // @ts-ignore
        const decoded = jwtDecode<UserData>(token);
        setUserData(decoded);
        console.log(decoded);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, []);

  // Dummy data for the chart
  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Number of Services Booked',
        data: [5, 9, 4, 6, 5, 8], // Replace with real data
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgba(59, 130, 246, 1)',
        borderWidth: 1,
      },
    ],
  };
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    navigate('/');
  };


  if (!userData) {
    navigate('/');
    return <div>Loading...</div>;
  }
  const imageUrl = getProfileImageUrl(userData.picture);
  const bookingHistory = [
  {
    id: 1,
    service: 'Plumbing',
    date: '2024-01-10',
    time: '10:00 AM',
    price: '75.00',
    provider: 'GoodPlumb Inc.',
    status: 'Completed',
  },
  {
    id: 2,
    service: 'Electrical Repair',
    date: '2024-01-12',
    time: '1:00 PM',
    price: '90.00',
    provider: 'Sparky Electricity',
    status: 'Scheduled',
  },
  // ... add more bookings as needed
];


const cancelBooking = (bookingId: number) => {
    // Logic to cancel booking by ID
    console.log(`Booking with ID ${bookingId} canceled.`);
    // You will likely need to call an API to handle cancellation on the backend
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-700">
      <NavBar />
      <div className="container mx-auto p-6 md:p-10 bg-white rounded-lg shadow-md mt-10 max-w-4xl">
      <div className="p-4">
  <div className="max-w-md mx-auto sm:max-w-xl lg:max-w-4xl">
    {/* Profile card */}
    <div className="bg-white shadow-xl rounded-lg overflow-hidden">
      {/* Header */}
      <div className="bg-blue-500 p-4 flex justify-between items-center">
        <h2 className="text-xl font-bold leading-7 text-white sm:text-2xl sm:truncate">
          Welcome back, {userData.first_name}
        </h2>
        {/* Edit Profile Button - shown only on larger screens */}
        <button className="hidden sm:block bg-white hover:bg-gray-100 text-blue-500 py-2 px-4 rounded-full transition ease-in-out duration-300 shadow">
          Edit Profile
        </button>
      </div>

      {/* Profile details */}
      <div className="flex flex-col items-center p-4">
        {/* Profile Image */}
        <div className="flex-shrink-0 mb-4">
          <img className="h-24 w-24 sm:h-32 sm:w-32 rounded-full" src={imageUrl} alt="Profile" />
        </div>

        {/* User Information */}
        <div className="text-center space-y-2">
          <h3 className="text-lg sm:text-xl font-semibold text-gray-800">{userData.first_name} {userData.last_name}</h3>
          <p className="text-blue-300 text-sm sm:text-base">{userData.username}</p>
          <p className="text-gray-600 text-sm sm:text-lg">{userData.email}</p>
          <p className="text-gray-600 text-sm sm:text-lg">{userData.phone_number}</p>
          {/* Additional details here */}
        </div>
        {/* Edit Profile Button - shown only on smaller screens */}
        <div className="text-center mt-4 sm:hidden">
          <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full transition ease-in-out duration-300">
            Edit Profile
          </button>
        </div>
      </div>
    </div>

  </div>
</div>


        {/* Booking History Section */}
  <div className="mt-8 relative">
    <h3 className="text-2xl font-semibold text-blue-500 mb-4">Booking History</h3>
    <div className="overflow-x-auto scrollbar scrollbar-thumb-blue-500 scrollbar-track-blue-100">
      <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-blue-200 bg-blue-100 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider">
                Service
              </th>
              <th className="px-5 py-3 border-b-2 border-blue-200 bg-blue-100 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider">
                Date
              </th>
              <th className="px-5 py-3 border-b-2 border-blue-200 bg-blue-100 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider">
                Time
              </th>
              <th className="px-5 py-3 border-b-2 border-blue-200 bg-blue-100 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider">
                Price
              </th>
              <th className="px-5 py-3 border-b-2 border-blue-200 bg-blue-100 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider">
                Provider
              </th>
              <th className="px-5 py-3 border-b-2 border-blue-200 bg-blue-100 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider">
                Status
              </th>
              <th className="px-5 py-3 border-b-2 border-blue-200 bg-blue-100 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {bookingHistory.map((booking) => (
              <tr key={booking.id}>
                <td className="px-5 py-5 border-b border-blue-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{booking.service}</p>
                </td>
                <td className="px-5 py-5 border-b border-blue-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{booking.date}</p>
                </td>
                <td className="px-5 py-5 border-b border-blue-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{booking.time}</p>
                </td>
                <td className="px-5 py-5 border-b border-blue-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">${booking.price}</p>
                </td>
                <td className="px-5 py-5 border-b border-blue-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{booking.provider}</p>
                </td>
                <td className="px-5 py-5 border-b border-blue-200 bg-white text-sm">
                  <span className={`inline-flex text-xs font-semibold rounded-full px-2 py-1 text-white ${booking.status === 'Completed' ? 'bg-green-500' : 'bg-yellow-500'}`}>
                    {booking.status}
                  </span>
                </td>
                <td className="px-5 py-5 border-b border-blue-200 bg-white text-sm">
                  {booking.status !== 'Completed' && (
                    <button
                      onClick={() => cancelBooking(booking.id)}
                      className="text-red-500 hover:text-red-700 underline"
                    >
                      Cancel
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
          <div className=" text-center text-xs text-blue-500 mt-2 md:hidden">
      <span>Scroll for more →</span>
    </div>



    </div>



          <div className="pb-10 mt-4 pt-5 justify-center flex md:mt-0 md:ml-4 object-center">
          <button
            onClick={handleLogout}
            className="bg-blue-500 hover:bg-red-600 text-white py-2 px-4 rounded transition duration-300"
          >
            Logout
          </button>
          </div>

      </div>
      <Footer />
    </div>
  );

};

export default ProfilePage;
