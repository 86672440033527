import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import NavBar from '../../../components/navigation/Navbar';
import BackPic from '../../../assets/BackPic';

import PlumbPic from '../../../assets/plumPic';
import ElectPic from '../../../assets/ElectPic';
import { useLocation } from 'react-router-dom';

import { Services } from '../../../types/Services';
import { SubCategories } from '../../../types/Services';
import { fetchServices } from '../../../services/getServices';
import { subCategories } from '../../../services/getServices';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function FindSp2() {
  const [services, setServices] = useState<Services[]>([])
  const [subcategories, setSubCategories] = useState<SubCategories[]>([])
  const location = useLocation();

  // Function to parse query parameters
  const getQueryParams = (query: string) => {
    return new URLSearchParams(query);
  };

  // Extract the type parameter
  const type = getQueryParams(location.search).get('type');

  useEffect(() => {
    // Fetch services when component mounts
    const getServices = async () => {
      try {
        const fetchedServices = await fetchServices();
        setServices(fetchedServices);
        const fetchedSubCategories = await subCategories();
        setSubCategories(fetchedSubCategories);
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    };

    getServices();
  }, []);

  // Filter services based on the type
  const filteredServices = services.filter(service => service.parent_taxonomies && service.parent_taxonomies.name === type);
  const filteredSubCategories = subcategories.filter(subcategory => subcategory.parent && subcategory.parent.name === type);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <NavBar />
        <section className='relative overflow-hidden bg-gray-50'>
          <div className='relative px-4 mx-auto py-6 flex justify-center items-center'>
            <div className='flex flex-wrap gap-y-4 max-w-md max-w-lg max-w-xl'>
              <BackPic />
              {/* {type === 'Plumbing' && <PlumbPic />}
              {type === 'Electrical' && <ElectPic />} */}
              <div className='w-full font-bold px-2 mb-2 md:mb-4'>
                <p className='text-xl text-center md:text-4xl'>
                  {`Choose a ${type} service:`}
                </p>
              </div>
              {filteredServices.map(service => (
                <a
                  key={service.id}
                  href='personalinformation'
                  className='bg-white rounded-xl block shadow-md p-6 hover:bg-blue-100 hover:text-blue-500 transition-colors duration-300 ease-in-out w-full mb-4'
                >
                  {/* Image of the service with a larger size for better visibility */}
                  {service.image && (
                    <div className='flex justify-center mb-4'>
                      <img
                        src={service.image}
                        alt={`Image of ${service.name}`}
                        className='w-29 h-41 object-cover rounded-lg'
                      />
                    </div>
                  )}

                  {/* Service name, price, and description with a clear hierarchy */}
                  <div className='text-center'>
                    <h5 className='font-semibold text-xl mb-2'>
                      {service.name}
                    </h5>
                    {service.price && (
                      <p className='text-lg text-gray-800 mb-1'>
                        {`${service.price} MAD`}
                      </p>
                    )}
                    {service.description && (
                      <p className='text-md text-gray-600'>
                        {service.description}
                      </p>
                    )}
                  </div>
                </a>
              ))}


              {filteredSubCategories.map(subcategory => (
                <a
                  key={subcategory.slug} // Use a unique key for each service
                  href={`services?type=${subcategory.name}`}
                  className='bg-white rounded-xl inline-block shadow p-3 hover:text-blue-500 w-full'
                >
                  <h5 className='font-semibold text-center text-xs md:text-base'>
                    {subcategory.name} {/* Display the name of the service */}
                  </h5>
                </a>
              ))}
              <div className='px-2 flex justify-center w-full'>
                <a
                  href='customised'
                  className='bg-white rounded-xl inline-block shadow p-3 hover:text-blue-500 w-full text-red-500'
                >
                  <h5 className='font-semibold text-center text-xs md:text-base'>
                    Customized services
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

