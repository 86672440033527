import React from "react";

const ProfIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={22}
            height={22}
            fill='none'
            viewBox='0 0 32 32'
            strokeWidth='2.5'
            stroke='currentColor'
            aria-hidden='true'
            className='bi bi-person-circle'
        >
            <path
                className='st0'
                d='M6.8,23.7c2.8-1.6,6-2.4,9.2-2.4c3.3,0,6.5,0.9,9.2,2.4 M20,13.3c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4
            S20,11.1,20,13.3z M28,16c0,6.6-5.4,12-12,12S4,22.6,4,16S9.4,4,16,4S28,9.4,28,16z'
            />
        </svg>

    )
}

export default ProfIcon;