import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// @ts-ignore
import { ReviewsSection } from './components/reviews/reviews.tsx';
import Navbar from '../components/navigation/Navbar';
import Footer from '../components/navigation/footer';
import ServiceProviderList from "../components/service-provider/List";
// import ServiceCard from "./components/FindServiceProvider/NotOffered.tsx";
import Services from "../pages/components/FindServiceProvider/Services";
import { useTranslation } from 'react-i18next';

const meta = {
  title: 'Fixi | Home',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar />
        <section className='overflow-hidden bg-gradient-to-br from-blue-500 relative to-green-500 via-green-500 z-20'>
          <img
            className='absolute bottom-0 left-0 w-full h-full object-cover'
            src='https://images.unsplash.com/photo-1558618666-fcd25c85cd64?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHw1fHxwbHVtYmluZ3xlbnwwfHx8fDE2OTY3ODYwNzN8MA&ixlib=rb-4.0.3&q=85&w=1920'
            alt=''
          />
          <div className='flex flex-wrap z-10 relative'>
            <div className='relative w-full ml-auto items-center flex p-4 lg:px-14 bg-black bg-opacity-50'>
              <div className=''>
                <h2 className='font-heading text-white font-bold text-2xl text-4xl'>
                  {t('SmallAd')}
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className='fixed top-0 w-full bg-white shadow-md z-10'>
          <div className='lg:px-12 py-3 px-4'>

            <Navbar />

            {/* You have to get back to this and work on it */}
          </div>
          <div className='relative flex bg-white w-full items-center -m-1 lg:px-12 mx-auto overflow-auto px-2 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch py-1'>
            <ul className='flex text-xs text-gray-900 whitespace-nowrap gap-x-2'>
              {/* Plumbing Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500'
                  href='#'
                >
                  <span>Plumbing</span>
                </a>
              </li>
              {/* Electrical Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Electrical</span>
                </a>
              </li>
              {/* HVAC (Heating, Ventilation, and Air Conditioning) Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>HVAC</span>
                </a>
              </li>
              {/* Carpentry Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Carpentry</span>
                </a>
              </li>
              {/* Painting Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Painting</span>
                </a>
              </li>
              {/* Home Cleaning Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Home Cleaning</span>
                </a>
              </li>
              {/* Appliance Repair Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Appliance Repair</span>
                </a>
              </li>
              {/* Roofing Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Roofing</span>
                </a>
              </li>
              {/* Landscaping and Lawn Care Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Landscaping and Lawn Care</span>
                </a>
              </li>
              {/* Pest Control Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Pest Control</span>
                </a>
              </li>
              {/* Locksmith Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Locksmith</span>
                </a>
              </li>
              {/* Home Security Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Home Security</span>
                </a>
              </li>
              {/* Garage Door Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Garage Door</span>
                </a>
              </li>
              {/* Flooring Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Flooring</span>
                </a>
              </li>
              {/* Window and Door Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Window and Door</span>
                </a>
              </li>
              {/* Home Improvement and Renovation Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Home Improvement and Renovation</span>
                </a>
              </li>
              {/* Interior Design Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Interior Design</span>
                </a>
              </li>
              {/* Moving and Packing Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Moving and Packing</span>
                </a>
              </li>
              {/* Handyman Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Handyman</span>
                </a>
              </li>
              {/* Pool and Spa Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Pool and Spa</span>
                </a>
              </li>
              {/* Auto Repair Service */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Auto Repair</span>
                </a>
              </li>
              {/* Pet Services */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Pet Services</span>
                </a>
              </li>
              {/* Elderly Care Services */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Elderly Care Services</span>
                </a>
              </li>
              {/* Event Planning Services */}
              <li className=''>
                <a
                  className='flex items-center py-1 px-2 rounded-full border border-gray-200 hover:bg-blue-100 hover:border-blue-500 hover:text-blue-500 '
                  href='#'
                >
                  <span>Event Planning Services</span>
                </a>
              </li>
            </ul>
            <div className='right-0 bg-gradient-to-r from-transparent to-white content-center justify-end items-center via-white lg:px-12 lg:flex hidden text-gray-700 fixed h-12'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={22}
                height={22}
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='currentColor'
                aria-hidden='true'
                className='cursor-pointer'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 5l7 7-7 7'
                />
              </svg>
            </div>
          </div>
        </section>



        <Services />



        <ServiceProviderList />
        <ReviewsSection />
        <section className='bg-gray-50' style={{}}>
          <div className='container px-4 mx-auto py-6'>
            <div className='relative max-w-7xl mx-auto px-6 md:px-12 py-16 sm:py-28 overflow-hidden bg-red-500 rounded-xl'>
              <div className='relative text-center'>
                <span className='block text-2xl sm:text-3xl md:text-4xl font-semibold mb-6 text-white'>
                  Urgent repairs required?? We've got you covered.
                </span>
                <a
                  className='relative group inline-block w-full sm:w-auto py-4 px-6 text-orange-900 font-semibold bg-white rounded-md overflow-hidden transition duration-300'
                  href='#'
                >
                  <div className='relative flex items-center justify-center'>
                    <span className='mr-4'>Get Help Now</span>
                    <span>
                      <svg
                        width={8}
                        height={12}
                        viewBox='0 0 8 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6.83 5.28999L2.59 1.04999C2.49704 0.956266 2.38644 0.881872 2.26458 0.831103C2.14272 0.780334 2.01202 0.754196 1.88 0.754196C1.74799 0.754196 1.61729 0.780334 1.49543 0.831103C1.37357 0.881872 1.26297 0.956266 1.17 1.04999C0.983753 1.23736 0.879211 1.49081 0.879211 1.75499C0.879211 2.01918 0.983753 2.27263 1.17 2.45999L4.71 5.99999L1.17 9.53999C0.983753 9.72736 0.879211 9.98081 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.70999C6.92373 6.61703 6.99813 6.50643 7.04889 6.38457C7.09966 6.26271 7.1258 6.13201 7.1258 5.99999C7.1258 5.86798 7.09966 5.73728 7.04889 5.61542C6.99813 5.49356 6.92373 5.38296 6.83 5.28999Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden py-6 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-7xl mx-auto'>
              <div className='flex flex-wrap -mx-4 items-center'>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='max-w-md mx-auto lg:mx-0 mb-4'>
                    <h3 className='w-full font-bold text-center text-3xl'>
                      Become a partner
                    </h3>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='relative max-w-md lg:max-w-2xl mx-auto lg:mr-0'>
                    <img
                      className='block w-full h-160 rounded object-cover overflow-hidden'
                      src='https://images.unsplash.com/photo-1582489852031-9a1ad48ed893?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHw0M3x8d29ya2VyfGVufDB8fHx8MTY5NjgxMzQzNHww&ixlib=rb-4.0.3&q=85&w=1920'
                      alt=''
                    />
                    <div className='absolute bottom-0 w-full left-0'>
                      <div className='backdrop-blur-md backdrop-filter bg-black bg-opacity-30 p-4 rounded-t-5xl'>
                        <p className='text-white font-medium mb-6'>
                          Partnering with FIXI is more than just a business
                          opportunity—it's a chance to grow, excel, and thrive
                          in the competitive home maintenance industry.
                        </p>
                        <a
                          href='https://forms.gle/wX2VBYkuyncmM31H9'
                          className='bg-white rounded-xl inline-block shadow p-3 flex gap-x-2 items-center w-full hover:text-blue-500'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <h5 className='font-semibold flex-1 text-sm'>
                            Register Here
                          </h5>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={2}
                            stroke='currentColor'
                            aria-hidden='true'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M17 8l4 4m0 0l-4 4m4-4H3'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative overflow-hidden bg-gray-50 py-6 pb-22'>
          <div className='relative container px-4 mx-auto'>
            <div className='max-w-5xl mx-auto'>
              <div className='mb-4'>
                <span className='inline-block py-1 px-3 text-xs font-semibold rounded-full bg-blue-50 text-blue-500 mb-2'>
                  FREQUENTLY ASK QUESTION
                </span>
                <h1 className='text-xl w-full font-bold'>
                  Answers to your questions
                </h1>
              </div>
              <div className='bg-white rounded-4xl shadow-lg p-4 pt-8 text-xs'>
                <button className='flex group w-full items-start justify-between border-b border-gray-100 text-left pb-4 mb-4'>
                  <div className='max-w-xl pr-5'>
                    <h3 className='font-semibold text-black group-hover:text-blue-500'>
                      How does Fixi ensure the quality of service providers on the platform?

                    </h3>
                    <p className='hidden group-hover:block mt-3 text-lg text-gray-500'>
                      At Fixi, we meticulously vet all service providers to ensure they meet our high standards for quality and professionalism. Providers must go through a comprehensive application process that includes verification of qualifications, references, and work history. We also encourage our user community to rate and review providers, ensuring transparency and continued excellence.
                    </p>
                  </div>
                  <div className='pt-1'>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={17}
                        height={3}
                        viewBox='0 0 17 3'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z'
                          fill='#FF460C'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </button>
                <button className='flex group w-full items-start justify-between border-b border-gray-100 text-left pb-4 mb-4'>
                  <div className='max-w-xl pr-5'>
                    <h3 className='font-semibold text-black group-hover:text-blue-500'>
                      What types of services can I find on Fixi?
                    </h3>
                    <p className='hidden group-hover:block mt-3 text-lg text-gray-500'>
                      Fixi is a diverse marketplace offering a wide range of services. Whether you’re looking for home repairs, tutoring, event planning, personal training, or more specialized professional services, our platform connects you with qualified providers ready to help. Simply browse our categories or use the search function to find the service you need.

                    </p>
                  </div>
                  <div className='pt-1'>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={17}
                        height={3}
                        viewBox='0 0 17 3'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z'
                          fill='#FF460C'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </button>
                <button className='flex group w-full items-start justify-between border-b border-gray-100 text-left pb-4 mb-4'>
                  <div className='max-w-xl pr-5'>
                    <h3 className='font-semibold text-black group-hover:text-blue-500'>
                      Is there a fee to use the Fixi platform as a customer?
                    </h3>
                    <p className='hidden group-hover:block mt-3 text-lg text-gray-500'>
                      No, there is no fee for customers to search and connect with service providers on Fixi. You only pay for the service you book directly with the provider. Our goal is to make finding and booking services as accessible and straightforward as possible.
                    </p>
                  </div>
                  <div className='pt-1'>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={17}
                        height={3}
                        viewBox='0 0 17 3'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z'
                          fill='#FF460C'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </button>
                <button className='flex group w-full items-start justify-between border-b border-gray-100 text-left pb-4 mb-4'>
                  <div className='max-w-xl pr-5'>
                    <h3 className='font-semibold text-black group-hover:text-blue-500'>
                      How does payment work on Fixi? Do I pay the service provider directly?

                    </h3>
                    <p className='hidden group-hover:block mt-3 text-lg text-gray-500'>
                      Fixi facilitates secure payments through our platform for your peace of mind and convenience. Once you've agreed on the service details with the provider, you'll pay through Fixi’s secure payment system. We hold the funds until the job is completed to your satisfaction, ensuring a reliable and trustworthy transaction.
                    </p>
                  </div>
                  <div className='pt-1'>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={17}
                        height={3}
                        viewBox='0 0 17 3'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z'
                          fill='#FF460C'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </button>
                <button className='flex group w-full items-start justify-between text-left'>
                  <div className='max-w-xl pr-5'>
                    <h3 className='font-semibold text-black group-hover:text-blue-500'>
                      What happens if the service is not delivered as expected?
                    </h3>
                    <p className='hidden group-hover:block mt-3 text-lg text-gray-500'>
                      We stand by the quality of our service providers, but should there be any issues with the delivery of service, Fixi offers a resolution process. Contact us within 48 hours of the service completion date, and we will work with you and the provider to make it right, which may include a partial refund or rebooking the service at no additional cost to you.

                    </p>
                  </div>
                  <div className='pt-1'>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={17}
                        height={3}
                        viewBox='0 0 17 3'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z'
                          fill='#FF460C'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={17}
                        height={16}
                        viewBox='0 0 17 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z'
                          fill='black'
                        />
                      </svg>
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </section>
                <Footer />

      </>
    </React.Fragment>
  );
}