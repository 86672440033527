import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ProfIcon from '../../assets/ProfIcon';

import Drawer from '../Drawer';
import NavigationMenu from '../NavigationManu';

import { useTranslation } from 'react-i18next';

// import logo from '../assets/logo.png';

const NavBar = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const { t, i18n } = useTranslation();

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  const links = [
    { title: 'About Us', href: '/about' },
    { title: 'Help', href: '/help' },
    { title: 'Settings', href: '/settings' },
    // Add more links as needed
  ];

  type maintenanceProp = {
    isMaintenanceMode: boolean;

  }



  const MaintenanceCard: React.FC<maintenanceProp> = ({ isMaintenanceMode }) => {
    useEffect(() => {
      const maintenanceState = isMaintenanceMode; // This would be dynamically determined
      setIsMaintenanceMode(maintenanceState);
    }, []);
    //if maintaince return this
    if (isMaintenanceMode) {
      return (
        <div className=" top-0 left-0 w-full bg-yellow-300 text-black text-center p-4 z-50 text-lg md:text-xl">
          {t('Notice')}
        </div>
      );
    }
    //if not return nothing
    return null;
  }
  const maintenanceNoticeHeight = isMaintenanceMode ? '40px' : '0px';
  console.log(maintenanceNoticeHeight);


  return (
    <React.Fragment>
      <>

        <section className='fixed  relative w-full bg-white z-30 '>
          <div className='py-3 px-4 container mx-auto'>
            <nav className='flex justify-between'>
             
              <div className='hidden md:flex w-full items-center flex-wrap justify-between relative'>
              <MaintenanceCard isMaintenanceMode={true}
              />
                <div className='flex items-center  z-10'>
                  <div className='font-bold rounded-full bg-white text-blue-500 hover:bg-blue-100'>
                    <h1 className='block text-3xl'>Fixi</h1>
                  </div>

                </div>


                <div className='flex items-center justify-end z-10'>
                  {/* <button className='font-bold rounded-full tracking-wide bg-blue-500 text-white hover:bg-blue-600 px-5 p-3'>
                    <span className='block text-lg'>Hire a professional</span>
                  </button> */}
                  <div className='w-full bg-white z-30 flex justify-end space-x-2 p-3'>
                    <button
                      onClick={() => handleLanguageChange('en')}
                      className='bg-blue-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded-full transition duration-300'
                    >
                      English
                    </button>
                    <button
                      onClick={() => handleLanguageChange('fr')}
                      className='bg-blue-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded-full transition duration-300'
                    >
                      French
                    </button>
                  </div>

                  <div className='group relative'>
                    <a
                      className='flex relative inline-block rounded-full text-gray-700 hover:text-gray-800 font-bold items-center ml-2 p-3 bg-gray-100 hover:bg-gray-200 ml-3'
                      href='#'
                    >
                      <ProfIcon />
                    </a>
                    <ul className='group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none absolute bg-white min-w-full shadow-md flex flex-col whitespace-nowrap right-0 top-12 rounded-2xl py-2 opacity-0 overflow-hidden z-50'>
                      <Link to="/signin">
                        <li className='cursor-pointer hover:bg-gray-100 flex items-center px-4 py-2 hover:text-blue-500'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width={22}
                            height={22}
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            aria-hidden='true'
                            className='mr-2'
                          >
                            <path
                              transform='translate(24) scale(-1, 1)'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1'
                            />
                          </svg>
                          <div className="text-sm text-gray-900">
                            Log in
                          </div>
                        </li>

                      </Link>
                      <Link to="/signup">
                        <li className='cursor-pointer hover:bg-gray-100 flex items-center px-4 py-2 hover:text-blue-500'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width={22}
                            height={22}
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            aria-hidden='true'
                            className='mr-2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z'
                            />
                          </svg>
                          <div className='text-sm text-gray-900'>
                            Sign up
                          </div>
                        </li>
                      </Link>
                      <li className='flex items-center py-2 px-4'>
                        <div className='w-full bg-gray-100 bg-gray-900 border-b'></div>
                      </li>
                      <li className='flex items-center py-2 px-4'>
                        <div className='w-full bg-gray-100 bg-gray-900 border-b'></div>
                      </li>
                      <li className='cursor-pointer hover:bg-gray-100 flex items-center px-4 py-2 hover:text-blue-500'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={22}
                          height={22}
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='currentColor'
                          aria-hidden='true'
                          className='mr-2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                          />
                        </svg>
                        <a href='#' className='text-sm text-gray-900'>
                          help
                        </a>
                      </li>
                      <li className='cursor-pointer hover:bg-gray-100 flex items-center px-4 py-2 hover:text-blue-500'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={22}
                          height={22}
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='currentColor'
                          aria-hidden='true'
                          className='mr-2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z'
                          />
                        </svg>
                        <a href='#' className='text-sm text-gray-900'>
                          Signaler un bug
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
             
              <div className='md:hidden flex flex-wrap justify-between w-full items-center' >
             
                <div className='flex items-center  z-10' >
                  <div className='font-bold rounded-full bg-white text-blue-500 hover:bg-blue-100'>
                    <h1 className='block text-3xl'>Fixi</h1>
                  </div>
                </div>
                <div className='flex flex-wrap'>
                  <button
                    className='flex relative inline-block rounded-full text-gray-700 font-bold items-center p-2 hover:bg-blue-500 hover:text-white'
                    onClick={toggleDrawer} // Updating click event handler
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={20}
                      height={20}
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      aria-hidden='true'
                    >
                      <path strokeLinecap='round' strokeLinejoin='round' d='M4 6h16M4 12h16m-7 6h7' />
                    </svg>
                  </button>
                </div>
                <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer}>
                  <NavigationMenu links={links} />
                </Drawer>


              </div>

            </nav>
          </div>
        </section>
      </>
    </React.Fragment >
  );
};

export default NavBar;
