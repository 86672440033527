import React, { useState, useEffect } from 'react';
import { fetchReviews } from '../../../services/reviews';
import { Review } from "../../../types/Reviews"; // Adjust the path as necessary

export const ReviewsSection: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [error, setError] = useState<string | null>(null);
  // @ts-ignore
  const StarIcon = ({ filled }) => {
    return filled ? (
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="text-blue-500 w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="text-blue-500 w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
      </svg>
    );
  };


  useEffect(() => {
    const getReviews = async () => {
      try {
        const fetchedReviews = await fetchReviews();
        // console.log('fetchedReviews', fetchedReviews);
        setReviews(fetchedReviews);
      } catch (error) {
        setError('Failed to fetch reviews');
      }
    };

    getReviews();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className='relative bg-gray-50 overflow-hidden py-6'>
      <div className='relative container px-4 mx-auto'>
        <div className='max-w-7xl mx-auto'>
          <div className='flex flex-wrap items-center'>
            <div className='w-full mb-4'>
              <span className='inline-block py-1 px-3 text-xs font-semibold rounded-full bg-blue-50 text-blue-500 mb-2'>
                TESTIMONIALS
              </span>
              <p className='text-xl w-full font-bold'>What our users say</p>
            </div>
          </div>
          <div className='flex flex-wrap -mx-4 gap-y-4'>
            {/* Map through reviews array */}
            {reviews.map((review, index) => (
              <div className='w-full lg:w-1/3 px-4' key={index}>
                <div className='flex flex-col justify-between h-full mx-auto bg-white shadow-lg overflow-hidden rounded-xl text-xs'>
                  <div className='p-3'>
                    <div className='flex items-center gap-x-1 mb-2'>
                      {/* Dynamically generate stars based on review data */}
                      {[...Array(5)].map((_, i) => (
                        <StarIcon key={i} filled={i < review.stars} />
                      ))}
                    </div>
                    <p className='text-gray-900'>
                      {review.message} {/* Use message from review data */}
                    </p>
                  </div>
                  <div className='p-3 bg-blue-500'>
                    <div className='flex items-center'>
                      <img
                        className='block rounded-full object-cover overflow-hidden w-8 h-8'
                        src={review.user.picture} // Use user picture from review data
                        alt={`${review.user.first_name} ${review.user.last_name}`} // Alt attribute for accessibility
                      />
                      <div className='ml-2'>
                        <span className='block text-white font-semibold leading-none'>
                          {review.user.first_name} {review.user.last_name} {/* Use name from review data */}
                        </span>
                        <span className='block text-blue-50'>
                          {review.user.title} {/* Use title from review data */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

// Make sure to also define the StarIcon component somewhere in your code if you haven't already.
